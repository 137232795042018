import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeaderLg from "../Header/HeaderLg";
import HeaderLg2 from "../Header/HeaderLg2";
import Home from "../MainFiles/Home";
import Embroidery from "../MainFiles/Embroidery";
import Print from "../MainFiles/Print";
import AllProduct from "../MainFiles/AllProduct";
import Free from "../MainFiles/Free";
import Applucoupon from "../MainFiles/Applucoupon";
import Custom from "../MainFiles/Custom";
import Favourite from "../MainFiles/Favourite";
import Carts from "../MainFiles/Carts";
import MyOrders from "../MainFiles/MyOrders";
import Embroideryview from "../MainFiles/Embroideryview";
import Printview from "../MainFiles/Printview";
import ReturnFundsPolicies from "../MainFiles/ReturnFundsPolicies";
import Deliveryinformation from "../MainFiles/Deliveryinformation";
import PrivacyPolicy from "../MainFiles/PrivacyPolicy";
import AboutUs from "../MainFiles/AboutUs";
import ProdByTag from "../MainFiles/ProdByTag";
import Search from "../MainFiles/Search";
import ResetPassword from "../MainFiles/ResetPassword";
import Successmeg from "../MainFiles/Successmeg";
import Orderdetail from "../MainFiles/Orderdetail";
import Termsandcondition from "../Home/Termsandcondition";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <HeaderLg />
        <HeaderLg2 />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product-category/embroidery-designs" element={<Embroidery />} />
          <Route path="/product-category/print-designs" element={<Print />} />
          <Route path="/all-design" element={<AllProduct />} />
          <Route path="/freebies" element={<Free />} />
          <Route path="/coupon" element={<Applucoupon />} />
          <Route path="/custom-digitizing" element={<Custom />} />
          <Route path="/my-wishlist" element={<Favourite />} />
          <Route path="/shopping-cart" element={<Carts />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/embroidery-detail" element={<Embroideryview />} />
          <Route path="/print-detail" element={<Printview />} />
          <Route path="/return-refund-policy" element={<ReturnFundsPolicies />} />
          <Route path="/delivery-information" element={<Deliveryinformation />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/embroidery-design/:slug" element={<Embroideryview />} />
          <Route path="/print-design/:slug" element={<Embroideryview />} />
          <Route path="/product-tag/:tag" element={<ProdByTag />} />
          <Route path="/search" element={<Search />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="/order-success/:id" element={<Successmeg />} />
          <Route path="/order-detail/:id" element={<Orderdetail />} />
          <Route path="/termsandcondition" element={<Termsandcondition />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
