import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import { Button } from "@mui/material";
import Card from "../Card/Card";
import Footer from "../Footer/Footer";
import { API_URL } from "../Config";
import { Pagination } from "antd";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?: [string];
}

const Search = () => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page from API
  const [totalPages, setTotalPages] = useState(1); // Total pages from API
  const location = useLocation();
  const itemsPerPage = 10; // Number of items per page

  // Extract query parameter from the URL
  const queryParams = new URLSearchParams(location.search);
  const searchValue = queryParams.get("query")?.toLowerCase() || "";

  const getAllProduct = async (page: number) => {
    try {
      // Construct the API URL dynamically based on the query parameter
      const queryParam = searchValue
        ? `queryValue=${encodeURIComponent(searchValue)}`
        : "";
      const response = await axios.get(
        `${API_URL}/api/product/filter?page=${page}&limit=${itemsPerPage}&${queryParam}`
      );

      const { products, totalProducts, currentPage, totalPages } =
        response.data;

      // Update state with fetched data
      setFilteredProducts(products.filter((item: Product) => !item.isFreebie));
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handlePageChange = (page: number) => {
    getAllProduct(page); // Fetch data for the selected page
  };

  useEffect(() => {
    getAllProduct(currentPage); // Fetch products on page or search value change
  }, [currentPage, searchValue]);

  return (
    <>
      <section
        className="pb-4"
        style={{ backgroundColor: "#FFCEDE", paddingTop: "4rem" }}
      >
        <Container>
          <div className="position-relative">
            <h2 className="title">Search items</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
              alt="img"
              loading="lazy"
            />
          </div>
          {filteredProducts.length ? (
            <>
              <Card data={filteredProducts} />
              <div className="pagination-controls text-center mt-4">
                <Pagination
                  current={currentPage} // Current page from API
                  total={totalPages * itemsPerPage} // Total items for pagination
                  pageSize={itemsPerPage} // Items per page
                  onChange={handlePageChange} // Page change handler
                  showSizeChanger={false} // Optional: Hide page size changer
                />
              </div>
            </>
          ) : (
            <h2 className="text-center">Oops no related items!</h2>
          )}
          <div className="text-center pt-5 pb-2">
            <Link to="/all-design">
              <Button className="button-medium" variant="contained">
                Explore More
              </Button>
            </Link>
          </div>
        </Container>
        <Footer />
      </section>
    </>
  );
};

export default Search;
