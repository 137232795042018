import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Pagination } from "antd";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API_URL } from "../Config";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?: [string];
}

const EmbroideryAllitems: React.FC = () => {
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("");

  const [totalprod, settotalProd] = useState({
    totalItems: null,
    showitems: null,
  });

  const fetchProducts = async (page: number, sortBy: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product/category/embroidery design?page=${currentPage}&limit=12&sortBy=${sortBy}`
      );

      const {
        products,
        totalCount,
        currentPage: serverPage,
        totalPages,
      } = response.data;
      settotalProd({
        ...totalprod,
        totalItems: totalCount,
        showitems: response.data.currentPage,
      });
      // Update state with fetched data
      setFilteredProducts(products);

      setCurrentPage(serverPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Fetch products whenever currentPage or sortBy changes
  useEffect(() => {
    fetchProducts(currentPage, sortBy);
  }, [currentPage, sortBy]);

  // Handle sort change
  const handleSortChange = (newSortBy: string) => {
    setSortBy(newSortBy);
    setCurrentPage(1);
  };

  // Example menu click handler
  const handleMenuClick = ({ key }: { key: string }) => {
    let newSortBy = "";

    switch (key) {
      case "1":
        newSortBy = "averageRating";
        break;
      case "2":
        newSortBy = "leastRating";
        break;
      case "3":
        newSortBy = "priceLowToHigh";
        break;
      case "4":
        newSortBy = "priceHighToLow";
        break;
      default:
        newSortBy = "";
    }

    handleSortChange(newSortBy);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Sort By Average Rating</span>,
    },
    {
      key: "2",
      label: <span>Sort By Least Rating</span>,
    },
    {
      key: "3",
      label: <span>Sort By Price: Low to High</span>,
    },
    {
      key: "4",
      label: <span>Sort By Price: High to Low</span>,
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchProducts(currentPage, sortBy);
  }, [currentPage, sortBy]);

  return (
    <>
      <Helmet>
        <title>Buy All Types of Embody Designs (Premium Quality)</title>
        <meta
          name="description"
          content="Browse from our list of dorzens of Embody designs, and choose the best one according to your preference."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="d-flex justify-content-between align-items-center">
            <div className="position-relative">
              <h2 className="title">Embroidery Design</h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                 alt="img"
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="Embroideryview-count mb-0">
                Showing {(totalprod?.showitems ?? 0) * 16 - 16}-
                {(totalprod?.showitems ?? 0) * 16} of{" "}
                {totalprod?.totalItems ?? 0} Results
              </p>
              <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                className="sort-dropdown mx-2"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Sort By
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
          <Card data={filteredProducts} />
          <div className="d-flex justify-content-center mt-4">
            <div className="pagination-controls text-center mt-4">
              <Pagination
                current={currentPage}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EmbroideryAllitems;
