import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { allProducts } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { API_URL } from "../Config";
import moment from "moment";

type coupon = {
  image: string;
  couponCode: string;
  discountPercentage: number;
  expiryDate: string;
};

const Coupon = () => {
  const [coupon, setCoupon] = useState<coupon | null>(null);
  const [isCoupon, setIsCoupon] = useState(false);
  const getAllCoupon = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllcoupon"));
      let length = firstResponse.coupons.length;
      if (
        moment(firstResponse.coupons[length - 1]?.expiryDate).format(
          "YYYY-MM-DD"
        ) >= moment(new Date()).format("YYYY-MM-DD")&&firstResponse.coupons.length>0
      ) {
        setIsCoupon(false);
        setCoupon(firstResponse.coupons[length - 1]);
      } else {
        setIsCoupon(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCoupon();
  }, []);

  return (
    <>
      <section style={{ backgroundColor: "#FFCEDE" }} className="py-4">
        <Container className="con">
          {isCoupon ? (
            <div className="text-center">
              <h3
                className="pb-3"
                style={{ fontWeight: 600, fontSize: "24px" }}
              >
                Not a Coupon In Sight!
              </h3>
              <img
                className="img-fluid"
                src="/assets/noc.png"
                style={{ width: "300px" }}
              />
              <h5 className="pt-3" style={{ fontWeight: 600 }}>
                Sorry no coupons available at the moment!
              </h5>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div>
                  <img
                    className="img-fluid coupon-img"
                    src={`${API_URL}/public/images/${coupon && coupon.image}`}
                     alt="img"
                loading="lazy"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="coupon-desc">
                  <h3>Discounts & Coupons</h3>
                  <h4>Welcome to the R G Embroidery Designs!</h4>
                  <p>
                    Enhance your embroidery collection with our premium designs!
                    Whether you’re working on a new project or looking to
                    explore fresh patterns, now is the perfect time to shop.
                    Enjoy a special {coupon && coupon.discountPercentage}{" "}
                    percentage offer on your next order of embroidery designs.
                  </p>
                  <p>
                    Browse our extensive collection of embroidery designs.
                    Select your favorite patterns and add them to your cart.
                    Apply the coupon code below at checkout to unlock your
                    discount.
                  </p>
                  <p>Coupon Code: {coupon && coupon.couponCode}</p>
                  <p>
                    Valid Until:{" "}
                    {coupon && moment(coupon.expiryDate).format("DD-MM-YYYY")}
                  </p>
                  <p>
                    Don’t miss out on this exclusive offer! Shop Now and bring
                    your creative visions to life with RG Designs.
                  </p>
                  <h6>Have a great shopping time!</h6>
                </div>
              </div>
            </div>
          )}
        </Container>
      </section>
    </>
  );
};

export default Coupon;
