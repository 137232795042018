import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../Footer/Footer'

const Termsandcondition = () => {
      useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      },[])
  return (
    <>
    <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className='con'>
            <div>
            <div className="position-relative">
              <h2 className="title">Terms and Condition</h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                 alt="img"
                loading="lazy"
              />
            </div>
            <div>
                <h3 style={{fontWeight:600,fontSize:"18px"}}>GENERAL POLICY</h3>
                <p>We place a great deal of importance on your pleasure. We are always pleased to take whatever necessary steps to guarantee that your purchasing experience is enjoyable. Since we're only human, we occasionally make mistakes. If you let us know, we'll fix them right away.All embroidery patterns and pictures are protected by copyright .DO YOU NEED ASSISTANCE?Please get in touch with us if you have any inquiries or if an issue occurs.</p>
                <p>Send the information below to  <a href='mailto:support@rgembroiderydesigns.com'>support@rgembroiderydesigns.com</a> via email.</p>
                <h3 style={{fontWeight:600,fontSize:"18px"}}>Quick Access to Downloadable Embroidery Files</h3>
                <p>The majority of our embroidery designs can be downloaded right away. However, if you pay with a debit card, a "e-check" might be generated, which could take three to five days to clear. Sometimes there will be a special that requires us to manually process the data before setting up downloads. As soon as your order is complete, you will receive an email confirmation from <a href="mailto:info@rgembroiderydesigns.com">info@rgembroiderydesigns.com</a> with a download link to your designs. This email will be sent to the primary email address you provided when you registered with us. Use the procedures above to get in touch with us if, within 24 hours of placing your order, you have not received this confirmation email. </p>
                <h3 style={{fontWeight:600,fontSize:"18px"}}>Free Giveaway</h3>
                <p>Some designs are "Time Limited Freebies" and are available for free for a maximum of 24 hours, while other designs might be free indefinitely.  Once the time limit expires, the free design will be available for purchase in our shop. You can easily buy it from our collection. Happy shopping!</p>
            </div>
            </div>
        </Container>
    </section>
    <Footer/>
    </>
  )
}

export default Termsandcondition