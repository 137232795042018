import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { API_URL } from "../Config";
import axios from "axios";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?: [string];
}

const Newarrive = () => {
  const [ogData, setOgData] = useState<Product[]>([]);

  const getAllProduct = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/product/latest`);
      setOgData(response.data);
    } catch (error) {
      console.error("Error fetching latest products:", error);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);

  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container>
          <div className="position-relative">
            <h2 className="title">New Arrival</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
              alt="img"
              loading="lazy"
            />
          </div>
          <Card data={ogData} />
          <div className="text-center pt-5 pb-2">
            <Link to="/all-design">
              <Button className="button-medium" variant="contained">
                Explore More
              </Button>
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Newarrive;
