import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import axios from "axios";
import { API_URL } from "../Config";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?: [string];
}

const Bestselling = () => {
  const [ogData, setOgData] = useState<Product[]>([]);

  const getAllProduct = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/api/product/category/embroidery design?&limit=4&sortBy=${"averageRating"}`
      );
      setOgData(response.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProduct();
  }, []);
  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container>
          <div className="position-relative">
            <h2 className="title">Best Selling</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
              alt="img"
              loading="lazy"
            />
          </div>
          <Card data={ogData} />
        </Container>
      </section>
    </>
  );
};

export default Bestselling;
