import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Dropdown, Pagination, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { allProducts } from "../Providers/Apicalls";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";
import axios from "axios";
import { API_URL } from "../Config";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  tags?: string;
  subimages?: [string];
  productTags?: string;
}

const ITEMS_PER_PAGE = 8;

const TagProduct = () => {
  const [sortedData, setSortedData] = useState<Product[]>([]);
  const { tag } = useParams<{ tag: string }>();
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("");

  const [totalprod, settotalProd] = useState({
    totalItems: null,
    showitems: null,
  });
  const getAllProduct = async (page: number, sortBy: string) => {
    try {
      const firstResponse = await axios.get(
        `${API_URL}/api/product/tag?productTags=${tag}&page=${currentPage}&limit=12&sortBy=${sortBy}`
      );
      const {
        products,
        totalCount,
        currentPage: serverPage,
        totalPages,
      } = firstResponse.data;
      settotalProd({
        ...totalprod,
        totalItems: totalCount,
        showitems: firstResponse.data.currentPage,
      });

      setCurrentPage(serverPage);
      setTotalPages(totalPages);

      setSortedData(firstResponse.data.products);
    } catch (error) {
      console.error(error);
    }
  };

  const [tagData, setTagData] = useState({
    metaTitle: "",
    tagContent: "",
    tagName: "",
    metaContent: "",
  });

  const getAllProductTags = async () => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("productTags"));
      const dta = firstResponse.productTags.filter(
        (val: any) => val.tagName.toLowerCase() === tag?.toLowerCase()
      );
      setTagData(dta[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProductTags();
  }, []);

  // Handle sort change
  const handleSortChange = (newSortBy: string) => {
    setSortBy(newSortBy);
    setCurrentPage(1);
  };

  const handleMenuClick = ({ key }: { key: string }) => {
    let newSortBy = "";

    switch (key) {
      case "1":
        newSortBy = "averageRating";
        break;
      case "2":
        newSortBy = "leastRating";
        break;
      case "3":
        newSortBy = "priceLowToHigh";
        break;
      case "4":
        newSortBy = "priceHighToLow";
        break;
      default:
        newSortBy = "";
    }

    handleSortChange(newSortBy);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span>Sort By Average Rating</span>,
    },
    {
      key: "2",
      label: <span>Sort By Least Rating</span>,
    },
    {
      key: "3",
      label: <span>Sort By Price: Low to High</span>,
    },
    {
      key: "4",
      label: <span>Sort By Price: High to Low</span>,
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getAllProduct(currentPage, sortBy);
  }, [currentPage, sortBy, tag]);

  return (
    <>
      <Helmet>
        <title>{tagData?.metaTitle}</title>
        <meta name="description" content={tagData?.metaContent} />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="d-flex justify-content-between align-items-center">
            <div className="position-relative">
              <h2 className="title">
                {" "}
                {tag
                  ? tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase()
                  : ""}
              </h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                 alt="img"
                loading="lazy"
              />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="Embroideryview-count mb-0">
                <p className="Embroideryview-count mb-0">
                  {/* {(totalprod?.showitems ?? 0) *16 -16}-{(totalprod?.showitems ?? 0) * 16} of */}
                  Showing {totalprod?.totalItems ?? 0} Results
                </p>
              </p>
              <Dropdown
                menu={{ items, onClick: handleMenuClick }}
                className="sort-dropdown mx-2"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    Sort By
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>

          <>
            <Card data={sortedData} />
            <div className="d-flex justify-content-center mt-4">
              <Pagination
                current={currentPage}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </div>
          </>
        </Container>
      </section>
      <section className="py-3" style={{ backgroundColor: "#FFCEDE" }}>
        <div style={{ backgroundColor: "#fff", borderRadius: "40px" }}>
          <Container>
            <div className="position-relative">
              <h2 className="title">
                {" "}
                {tag
                  ? tag.charAt(0).toUpperCase() +
                    tag.slice(1).toLowerCase() +
                    " " +
                    "Designs"
                  : ""}
              </h2>
              <img
                src="/assets/headdecor.png"
                className="img-fluid best-sellingDecor"
                 alt="img"
                loading="lazy"
              />
            </div>

            <p className="py-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(tagData?.tagContent || ""),
                }}
                style={{ fontSize: "16px", lineHeight: "1.5" }}
              />
            </p>
          </Container>
        </div>
      </section>
    </>
  );
};

export default TagProduct;
