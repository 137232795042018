import { Button } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const Sellingbanner = () => {
  return (
    <>
      <section style={{ backgroundColor: "#FFEDF3" }} className="py-3">
        <Container>
          <div className="row">
            <div className="col-lg-10 col-md-8 col-sm-8 d-flex justify-content-start align-items-center">
              <div>
                <h2 className="freebies-head">
                  "Freebies Galore
                  <span className="freespan"> – Take Yours Today!"</span>
                </h2>
                <div className="">
                  <Link to="/freebies">
                    <Button className="button-medium" variant="contained">
                      Explore More
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-4 bies-box">
              <div>
                <img
                  className="img-fluid bies-bannerimg"
                  src="/assets/Banner/freebe.png"
                  alt="img"
              loading="lazy"
                />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Sellingbanner;
