import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Card from "../Card/Card";
import { Button } from "@mui/material";
import { allProducts, getMe } from "../Providers/Apicalls";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { Link } from "react-router-dom";

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: any[];
  cart: any[];
  wishlist: string[];
  wallet: number | null;
  _id: string;
};

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  subimages?: [string];
}

const Wishlist = () => {
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const [ogData, setOgData] = useState<Product[]>([]);
  const [wishlistTrigger, setWishlistTrigger] = useState<string | null>(
    localStorage.getItem("wishlisttrigger")
  );

  const getUser = async () => {
    try {
      const getMeUser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeUser.user);
      await getAllProduct(getMeUser.user.wishlist);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllProduct = async (wishlist: string[]) => {
    try {
      const firstResponse = await allProducts(ApiEndPoints("getAllProduct"));
      const wishlistProducts = firstResponse.products.filter(
        (product: Product) =>
          product._id && wishlist.includes(product._id) && !product.isFreebie
      );
      setOgData(wishlistProducts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);


  useEffect(() => {
    const checkWishlistTrigger = () => {
      const currentValue = localStorage.getItem("wishlisttrigger");

      if (currentValue !== wishlistTrigger) {
        setWishlistTrigger(currentValue);
        getUser();
      }
    };

    const intervalId = setInterval(checkWishlistTrigger, 1000);

    return () => clearInterval(intervalId);
  }, [wishlistTrigger]);


  return (
    <>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="position-relative">
            <h2 className="title">My Wishlist</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
              alt="img"
              loading="lazy"
            />
          </div>
          {ogData.length ? (
            <Card data={ogData} />
          ) : (
            <h2 className="text-center">Oops no items found!</h2>
          )}
          <div className="text-center pt-5 pb-2">
            <Link to="/all-design">
              <Button className="button-medium" variant="contained">
                Explore More
              </Button>
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Wishlist;
