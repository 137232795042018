import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>
          Privacy Policy | RG Embroidery Designs - Your Data Security Matters
        </title>
        <meta
          name="description"
          content="Read the Privacy Policy of RG Embroidery Designs. We prioritize your data security and privacy. Learn how we protect your personal information and ensure a safe shopping experience."
        />
      </Helmet>
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="position-relative">
            <h2 className="title">Privacy policy</h2>
            <img
              src="/assets/headdecor.png"
              className="img-fluid best-sellingDecor"
              alt="img"
              loading="lazy"
            />
          </div>
          <div className="privacy">
            <p>
              At RG Embroidery Designs, we value your privacy and are committed
              to protecting your personal information. This Privacy Policy
              outlines how we collect, use, and safeguard your data when you
              visit our website, make purchases, or interact with us. By using
              our site, you consent to the practices described herein. Our goal
              is to be transparent and provide you with control over your
              personal information, ensuring a secure and trustworthy online
              experience.
            </p>
            <h2>Comments</h2>
            <p>
              When visitors leave comments, we collect the data shown in the
              comments form, and also the visitor’s IP address and browser user
              agent string to help spam detection. An anonymized string created
              from your email address (a hash) may be provided to the Gravatar
              service to see if you are using it. The Gravatar service privacy
              policy is available here. After approval of your comment, your
              profile picture is visible to the public in the context of your
              comment.
            </p>
            <h2>Media</h2>
            <p>
              If you upload images to the website, avoid uploading images with
              embedded location data (EXIF GPS) included. Visitors can download
              and extract any location data from images on the website.
            </p>
            <h2>Cookies</h2>
            <p>
              If you leave a comment, you may opt-in to saving your name, email
              address, and website in cookies for your convenience. These
              cookies will last for one year.
            </p>
            <p>
              When you visit our login page, a temporary cookie will determine
              if your browser accepts cookies. This cookie contains no personal
              data and is discarded when you close your browser.
            </p>
            <p>
              Upon logging in, we will set up several cookies to save your login
              information and screen display choices. Login cookies last for two
              days, and screen options cookies last for a year. Selecting
              “Remember Me” keeps your login persistent for two weeks. Logging
              out removes the login cookies.
            </p>
            <p>
              Editing or publishing an article will save an additional cookie in
              your browser, containing no personal data and simply indicating
              the post ID of the article you just edited. This expires after one
              day.
            </p>
            <h2>Embedded Content</h2>
            <p>
              Comments and their metadata are retained indefinitely. We store
              the personal information provided by users who register on our
              website in their user profiles. Users can view, edit, or delete
              their personal information at any time (except the username).
              Website administrators can also view and edit that information.
            </p>
            <p>Your Data Rights</p>
            <p>
              If you have an account on this site or have left comments, you can
              request to receive an exported file of the personal data we hold
              about you, including any data you have provided to us. You can
              also request that we erase any personal data we hold about you,
              excluding any data we are obliged to keep for administrative,
              legal, or security purposes.
            </p>
            <h2>Data Transmission</h2>
            <p>
              Visitor comments may be checked through an automated spam
              detection service.
            </p>
            <h2>Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information. Your personal information is
              contained behind secured networks. It is only accessible by a
              limited number of persons with special access rights to such
              systems, who must keep the information confidential.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes.
            </p>
            <h2>Contact Information</h2>
            <p>
              If you have any questions about this Privacy Policy, you can
              contact us:
            </p>
            <ul>
              <li>By email: rgembroiderydesigns@gmail.com</li>
              <li>By visiting this page on our website: Contact Us</li>
            </ul>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Privacy;
