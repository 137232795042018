import React, { useEffect, useState } from "react";
import { FaStar, FaRegHeart, FaEye, FaHeart } from "react-icons/fa";
import { AiOutlineShoppingCart, AiFillShopping } from "react-icons/ai";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Box, Button, Modal, Rating, Table } from "@mui/material";
import { Container } from "react-bootstrap";
import { API_URL } from "../Config";
import { ApiEndPoints } from "../Providers/api_endpoints";
import { deleteitem, forgetPass, getMe } from "../Providers/Apicalls";
import { message } from "antd";
import DOMPurify from "dompurify";
import { eventManager } from "../Eventemitter";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  priceValidityDate?: string;
  priceUpdateType?: string;
  pricePercentage?: number;
  subimages?: [string];
}

interface CardProps {
  data: Product[];
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: string[];
  wishlist: string[];
  wallet: number | null;
  _id: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Card: React.FC<CardProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const handleOpen = (product: Product) => {
    setSelectedProduct(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProduct(null);
  };

  const handleViewProduct = (key: any) => {
    if (key.category === "embroidery design") {
      navigate(`/embroidery-design/${key.slug}`);
    } else if (key.category === "print design") {
      navigate(`/print-design/${key.slug}`);
    } else {
      navigate("/freebies");
    }
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));

      // Set the user data to state
      setUserData(getMeuser.user);

      // Extract wishlist IDs from the user data
      const userWishlist = getMeuser.user.wishlist.map((item: any) => item);

      // Check if products in the current view are in the wishlist
      data.forEach((product) => {
        const isInWishlist = userWishlist.includes(product._id);
        isProductInWishlist(isInWishlist);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item === productId);
  };

  const handleWishlist = async (
    product: Product,
    action: "wishlist" | "cart"
  ) => {
    try {
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          // Remove from wishlist
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
          localStorage.setItem("wishlisttrigger", Math.random().toString());
          eventManager.emit("cartUpdated");
        } else {
          // Add to wishlist
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
          eventManager.emit("cartUpdated");
        }

        // Update local state
        setUserData((prevState) => {
          const updatedWishlist = isProductInWishlist(product._id!)
            ? prevState.wishlist.filter((id) => id !== product._id)
            : [...prevState.wishlist, product._id!];
          return { ...prevState, wishlist: updatedWishlist };
        });
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          // Remove from cart
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
          eventManager.emit("cartUpdated");
        } else {
          // Add to cart
          await forgetPass(ApiEndPoints("addtoCart"), payload);
          eventManager.emit("cartUpdated");
        }

        // Update local state
        setUserData((prevState) => {
          const updatedCart = isProductInCart(product._id!)
            ? prevState.cart.filter((id) => id !== product._id)
            : [...prevState.cart, product._id!];
          return { ...prevState, cart: updatedCart };
        });
      }

      successmessage(
        `Product ${
          action === "wishlist" ? "wishlist" : "cart"
        } updated successfully.`
      );
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <>
      {contextHolder}
      <div className="row mx-0">
        {data.map((val, index) => (
          <div className="col-lg-3 col-md-6 col-sm-6 col-6" key={index}>
            <div className="text-center position-relative card-hover py-3">
              <img
                src={
                  val.subimages?.[0]
                    ? `${API_URL}/public/subimages/${val.subimages[0]}`
                    : "path/to/default/image.jpg"
                }
                alt="product"
                className="img-fluid card-img"
                loading="lazy"
                onClick={() => handleViewProduct(val)}
              />
              <div className="product-icon">
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <div className="icons">
                      {isProductInWishlist(val._id!) ? (
                        <FaHeart
                          onClick={() => handleWishlist(val, "wishlist")}
                          style={{ color: "red" }}
                        />
                      ) : (
                        <FaRegHeart
                          onClick={() => handleWishlist(val, "wishlist")}
                        />
                      )}
                    </div>
                    <span className="count">{val.wishlistCount}</span>
                  </div>
                  <div>
                    <div className="icons">
                      {isProductInCart(val._id!) ? (
                        <AiFillShopping
                          onClick={() => handleWishlist(val, "cart")}
                          style={{ color: "green" }}
                        />
                      ) : (
                        <AiOutlineShoppingCart
                          onClick={() => handleWishlist(val, "cart")}
                        />
                      )}
                    </div>
                    <span className="count">{val.cartCount}</span>
                  </div>
                  <div>
                    <div className="icons" onClick={() => handleOpen(val)}>
                      <FaEye />
                    </div>
                    <span className="count" style={{ visibility: "hidden" }}>
                      0
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-details">
                <h6 className="mb-0 py-1">
                  {val.category
                    ? val.category.charAt(0).toUpperCase() +
                      val.category.slice(1).toLowerCase()
                    : "Unknown Category"}
                </h6>

                <p className="mb-0">{val.name}</p>
                <p className="mb-0">
                  {val.priceUpdateType === "" &&
                  new Date(
                    val.priceValidityDate ? val.priceValidityDate : ""
                  ) >= new Date() ? (
                    <span className="card-price">
                      $ {val.price?.toFixed(2)}
                    </span>
                  ) : (
                    <span className="card-price me-1">
                      {(() => {
                        const currentDate = new Date();
                        const validatyDate = val.priceValidityDate
                          ? new Date(val.priceValidityDate)
                          : null;
                        let newPrice = val.price;
                        let originalPrice = val.price;
                        let percentage = val.pricePercentage;

                        // Check if price update is valid based on the validatyDate
                        if (
                          validatyDate &&
                          validatyDate >= currentDate &&
                          val.priceUpdateType &&
                          val.pricePercentage &&
                          val.price !== undefined
                        ) {
                          if (val.priceUpdateType === "escalation") {
                            newPrice =
                              val.price +
                              (val.price * val.pricePercentage) / 100;
                          } else if (val.priceUpdateType === "reduction") {
                            newPrice =
                              val.price -
                              (val.price * val.pricePercentage) / 100;
                          }
                        }

                        // Return the newPrice, original price, and percentage for reduction
                        if (
                          val.priceUpdateType === "reduction" &&
                          percentage !== undefined
                        ) {
                          return (
                            <>
                              <span
                                className="reduction-price"
                                style={{ fontSize: "18px" }}
                              >
                                $ {newPrice?.toFixed(2)}
                              </span>{" "}
                              <span
                                className="og-price ms-1"
                                style={{
                                  textDecoration: "line-through",
                                  color: "#848484",
                                }}
                              >
                                $ {originalPrice?.toFixed(2)}
                              </span>
                            </>
                          );
                        }

                        // Return only the newPrice if not a reduction
                        return newPrice !== undefined
                          ? `$ ${newPrice.toFixed(2)}`
                          : "Price not available";
                      })()}
                    </span>
                  )}
                  |{" "}
                  <span className="d-inline-flex justify-content-center align-items-center">
                    {val.ratingsAverage}
                    <FaStar style={{ color: "#D327A1" }} />
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}

        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <section className="py-4" style={{ backgroundColor: "#FFEDF3" }}>
              <Container>
                <div className="text-end py-2">
                  <IoMdCloseCircleOutline
                    style={{ fontSize: "1.5rem", cursor: "pointer" }}
                    onClick={handleClose}
                  />
                </div>
                {selectedProduct && (
                  <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-12">
                      <div>
                        <img
                          src={`${API_URL}/public/subimages/${selectedProduct?.subimages?.[0]}`}
                          className="img-fluid "
                           alt="img"
                loading="lazy"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-7 col-md-6 col-sm-12"
                      style={{ overflowY: "auto" }}
                    >
                      <div className="py-3 px-4">
                        <div className="d-flex justify-content-between align-items-center">
                          <h1 className="embroidery-title">
                            {selectedProduct.name
                              ? selectedProduct.name
                              : "N/A"}
                          </h1>
                          <div className="heart-icon">
                            {isProductInWishlist(selectedProduct._id!) ? (
                              <FaHeart
                                onClick={() =>
                                  handleWishlist(selectedProduct, "wishlist")
                                }
                                style={{ color: "red" }}
                              />
                            ) : (
                              <FaRegHeart
                                onClick={() =>
                                  handleWishlist(selectedProduct, "wishlist")
                                }
                              />
                            )}
                          </div>
                        </div>
                        {selectedProduct.ratingsAverage ? (
                          <div className="d-flex justify-content-start align-items-center flex-wrap">
                            <Rating
                              name="read-only"
                              value={
                                selectedProduct.ratingsAverage
                                  ? selectedProduct.ratingsAverage
                                  : 0
                              }
                              readOnly
                            />
                            <p className="embroidery-rating mb-0">
                              {selectedProduct.ratingsAverage
                                ? selectedProduct.ratingsAverage
                                : "0"}{" "}
                              ratings *{" "}
                              {selectedProduct.reviewsCount
                                ? selectedProduct.reviewsCount
                                : "0"}{" "}
                              Reviews{" "}
                              <span className="embroidery-totalbuyers">
                                ({" "}
                                {selectedProduct.buyersCount
                                  ? selectedProduct.buyersCount
                                  : "0"}{" "}
                                Buyers)
                              </span>
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        <p className="embroidery-Category py-2">
                          Category :{" "}
                          {selectedProduct.category
                            ? selectedProduct.category
                            : "N/A"}
                        </p>
                        <div className="d-flex justify-content-between flex-wrap align-items-center mb-2">
                          <h3 className="mb-0">
                            {selectedProduct.priceUpdateType === "" &&
                            new Date(
                              selectedProduct.priceValidityDate
                                ? selectedProduct.priceValidityDate
                                : ""
                            ) >= new Date() ? (
                              <span className="card-price">
                                $ {selectedProduct.price?.toFixed(2)}
                              </span>
                            ) : (
                              <h3 className="embroidery-price">
                                {(() => {
                                  const currentDate = new Date();
                                  const validatyDate =
                                    selectedProduct.priceValidityDate
                                      ? new Date(
                                          selectedProduct.priceValidityDate
                                        )
                                      : null;
                                  let newPrice = selectedProduct.price;
                                  let originalPrice = selectedProduct.price;
                                  let percentage =
                                    selectedProduct.pricePercentage;

                                  // Check if price update is valid based on the validatyDate
                                  if (
                                    validatyDate &&
                                    validatyDate >= currentDate &&
                                    selectedProduct.priceUpdateType &&
                                    selectedProduct.pricePercentage &&
                                    selectedProduct.price !== undefined
                                  ) {
                                    if (
                                      selectedProduct.priceUpdateType ===
                                      "escalation"
                                    ) {
                                      newPrice =
                                        selectedProduct.price +
                                        (selectedProduct.price *
                                          selectedProduct.pricePercentage) /
                                          100;
                                    } else if (
                                      selectedProduct.priceUpdateType ===
                                      "reduction"
                                    ) {
                                      newPrice =
                                        selectedProduct.price -
                                        (selectedProduct.price *
                                          selectedProduct.pricePercentage) /
                                          100;
                                    }
                                  }

                                  // Return the newPrice, original price, and percentage for reduction
                                  if (
                                    selectedProduct.priceUpdateType ===
                                      "reduction" &&
                                    percentage !== undefined
                                  ) {
                                    return (
                                      <>
                                        {/* <span style={{fontSize:"14px"}}>{`-${selectedProduct.pricePercentage}%`}</span> */}
                                        <span className="embroidery-price">
                                          $ {newPrice?.toFixed(2)}
                                        </span>{" "}
                                        <span
                                          className="og-price ms-1"
                                          style={{
                                            textDecoration: "line-through",
                                            color: "#848484",
                                            fontSize: "16px",
                                          }}
                                        >
                                          $ {originalPrice?.toFixed(2)}
                                        </span>
                                        <span className="odd">
                                          {" "}
                                          {percentage}% OFF
                                        </span>
                                      </>
                                    );
                                  }

                                  // Return only the newPrice if not a reduction
                                  return newPrice !== undefined
                                    ? `$ ${newPrice.toFixed(2)}`
                                    : "Price not available";
                                })()}
                              </h3>
                            )}
                          </h3>
                          <div>
                            {isProductInCart(selectedProduct._id!) ? (
                              <Button
                                onClick={() =>
                                  handleWishlist(selectedProduct, "cart")
                                }
                                variant="contained"
                                className="button-medium me-2"
                              >
                                Added
                              </Button>
                            ) : (
                              <Button
                                onClick={() =>
                                  handleWishlist(selectedProduct, "cart")
                                }
                                variant="contained"
                                className="button-medium me-2"
                              >
                                Add To Cart
                              </Button>
                            )}

                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${API_URL}/public/subimages/${selectedProduct?.subimages?.[0]}&quote=Check%20out%20this%20amazing%20product!`}
                              target="_blank"
                              className="me-1"
                            >
                              <img
                                src="/assets/facebook (5).png"
                                className="img-fluid soc-img mt-0"
                              />
                            </a>

                            <a
                              href={`https://in.pinterest.com/pin-builder?url=${
                                window.location.href
                              }&media=${API_URL}/public/subimages/${
                                selectedProduct?.subimages?.[0]
                              }&description=Your%20Description%20Here"=${encodeURIComponent(
                                window.location.href
                              )}`}
                              target="_blank"
                              className="ms-1"
                            >
                              <img
                                src="/assets/social 1.png"
                                className="img-fluid soc-img mt-0"
                              />
                            </a>
                          </div>
                        </div>
                        <div>
                          {selectedProduct.category?.toLocaleLowerCase() ===
                          "embroidery design" ? (
                            <Table className="view-table">
                              <tbody>
                                <tr>
                                  <td>Sizes (Inches)</td>
                                  <td>
                                    {selectedProduct.sizes
                                      ? selectedProduct.sizes
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Stitch Count</td>
                                  <td>
                                    {selectedProduct.stitchCount
                                      ? selectedProduct.stitchCount
                                      : "-"}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Formats</td>
                                  <td>
                                    {selectedProduct.formats
                                      ? selectedProduct.formats
                                      : "-"}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Quantity</td>
                                  <td>
                                    {selectedProduct.sizes
                                      ? selectedProduct.sizes
                                      : "-"}{" "}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          ) : (
                            <>
                              <h2 className="product-desc">
                                Product Description
                              </h2>

                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    selectedProduct.description || ""
                                  ),
                                }}
                                style={{ fontSize: "16px", lineHeight: "1.5" }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Container>
            </section>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Card;
