import { Button, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";

import { Link, useParams } from "react-router-dom";
import { ApiEndPoints } from "../Providers/api_endpoints";
import DOMPurify from "dompurify";
import Slider from "react-slick";
import {
  deleteitem,
  forgetPass,
  getMe,
  getProductreview,
  getSlugProduct,
} from "../Providers/Apicalls";
import { API_URL } from "../Config";
import { message } from "antd";
import { Helmet } from "react-helmet";
import { eventManager } from "../Eventemitter";

interface Product {
  _id?: string;
  name: string;
  image: string;
  category?: string;
  price?: number;
  isFreebie?: boolean;
  date?: string;
  description?: string;
  sizes?: string | null;
  stitchCount?: number;
  colors?: number;
  formats?: string;
  quantity?: string;
  buyersCount?: number;
  wishlistCount?: number;
  cartCount?: number;
  reviewsCount?: number;
  ratingsAverage?: number;
  isDeleted?: boolean;
  priceValidityDate?: string;
  priceUpdateType?: string;
  pricePercentage?: number;
  keywords?: any;
  subimages?: [string];
}

interface Review {
  _id: string;
  user: {
    _id: string;
    name: string;
  };
  rating: number;
  review: string;
  date: string;
}

type UserData = {
  name: string;
  phone: number | null;
  email: string;
  role: string;
  orders: [];
  cart: Product[];
  wishlist: Product[];
  wallet: number | null;
  _id: string;
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 3,
};

const EmbroideryDesign = () => {
  const { slug } = useParams<{ slug: string }>();

  const [productData, setProductData] = useState<Product | null>(null);
  const [reviewData, setReviewData] = useState<Review[]>([]);
  const [visibleReviews, setVisibleReviews] = useState<Review[]>([]);
  const [reviewsShown, setReviewsShown] = useState<number>(2);
  const [userData, setUserData] = useState<UserData>({
    name: "",
    phone: null,
    email: "",
    role: "",
    orders: [],
    cart: [],
    wishlist: [],
    wallet: null,
    _id: "",
  });

  const [subImages, setSubimages] = useState<any[]>([]);
  const [initial, setInitial] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();

  const successmessage = (content: any) => {
    messageApi.open({
      type: "success",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const errormessage = (content: any) => {
    messageApi.open({
      type: "error",
      content: content,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
      },
    });
  };

  const handleProduct = async () => {
    try {
      const fetchProduct = await getSlugProduct(
        ApiEndPoints("getbyslug"),
        slug || ""
      );
      await handleReviewProduct(fetchProduct.product._id);
      setSubimages(fetchProduct.product.subimages);
      setProductData(fetchProduct.product);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  useEffect(() => {
    handleProduct();
  }, [slug]);

  const handleReviewProduct = async (id: string) => {
    try {
      const fetchReviews = await getProductreview(
        ApiEndPoints("getreviewbyID"),
        id
      );
      setReviewData(fetchReviews.reviews);
      setVisibleReviews(fetchReviews.reviews.slice(0, 2));
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  const handleSeeMore = () => {
    const nextReviews = reviewsShown + 10;
    setVisibleReviews(reviewData.slice(0, nextReviews));
    setReviewsShown(nextReviews);
  };

  const getUser = async () => {
    try {
      const getMeuser = await getMe(ApiEndPoints("getMe"));
      setUserData(getMeuser.user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const isProductInWishlist = (productId: string) => {
    return userData.wishlist.some((item) => item._id === productId);
  };

  const isProductInCart = (productId: string) => {
    return userData.cart.some((item) => item._id === productId);
  };

  const handleWishlist = async (
    product: Product,
    action: "wishlist" | "cart"
  ) => {
    try {
      // Prepare payload to be sent to the server
      const payload = { product: product._id };

      if (action === "wishlist") {
        if (isProductInWishlist(product._id!)) {
          // Remove from wishlist
          await deleteitem(ApiEndPoints("removeFromWishlist"), payload);
          eventManager.emit("cartUpdated");
          // Update local state to reflect removal
          setUserData((prevState) => ({
            ...prevState,
            wishlist: prevState.wishlist.filter(
              (item) => item._id !== product._id
            ),
          }));
        } else {
          // Add to wishlist
          await forgetPass(ApiEndPoints("addtoWishlist"), payload);
          eventManager.emit("cartUpdated");
          // Update local state to reflect addition
          setUserData((prevState) => ({
            ...prevState,
            wishlist: [...prevState.wishlist, product],
          }));
        }
      } else if (action === "cart") {
        if (isProductInCart(product._id!)) {
          // Remove from cart
          await deleteitem(ApiEndPoints("removeFromCart"), payload);
          eventManager.emit("cartUpdated");
          // Update local state to reflect removal
          setUserData((prevState) => ({
            ...prevState,
            cart: prevState.cart.filter((item) => item._id !== product._id),
          }));
        } else {
          // Add to cart
          await forgetPass(ApiEndPoints("addtoCart"), payload);
          eventManager.emit("cartUpdated");
          // Update local state to reflect addition
          setUserData((prevState) => ({
            ...prevState,
            cart: [...prevState.cart, product],
          }));
        }
      }

      successmessage(
        `Product ${
          action === "wishlist" ? "wishlist" : "cart"
        } updated successfully.`
      );
    } catch (error: any) {
      console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        errormessage(error.response.data.message);
      } else {
        errormessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    handleProduct();
  }, []);

  if (!productData) {
    return <div>Loading...</div>;
  }

  const handleValchage = (i: any) => {
    setInitial(i);
  };

  return (
    <>
      <Helmet>
        <title>{productData.name}</title>
        <meta property="og:site_name" content="Rg Embroidery Designs" />
        <meta
          property="og:image"
          content="https://app.rgembroiderydesigns.com/public/subimages/c099dab6-312c-4df2-9266-185b433fbafd.jpg"
        />
        <meta property="og:description" content={productData.description} />
        <meta property="og:title" content={productData.name} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {contextHolder}
      <section className="py-4" style={{ backgroundColor: "#FFCEDE" }}>
        <Container className="con">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 my-1">
              <div>
                <img
                  src={`${API_URL}/public/subimages/${subImages[initial]}`}
                  alt={productData.name}
                  className="img-fluid embroidery-img"
                  loading="lazy"
                />
              </div>
              {subImages.length > 1 && (
                <div className="slider-container">
                  <Slider {...settings}>
                    {subImages.length > 0 &&
                      subImages.map((val, i) => (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          key={i}
                        >
                          <div>
                            <img
                              loading="lazy"
                              className="mx-2 sub-imgg"
                              style={{ cursor: "pointer" }}
                              src={`${API_URL}/public/subimages/${val}`}
                              onClick={() => handleValchage(i)}
                              alt="img"
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              )}
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 product-heigth my-1">
              <div className="embroidery-content py-3 px-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="embroidery-title">{productData.name}</h1>
                  <div
                    className={
                      subImages.length > 2 ? "heart-icon2" : "heart-icon"
                    }
                  >
                    {isProductInWishlist(productData._id!) ? (
                      <FaHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <FaRegHeart
                        onClick={() => handleWishlist(productData, "wishlist")}
                      />
                    )}
                  </div>
                </div>
                {productData.ratingsAverage ? (
                  <div className="d-flex justify-content-start align-items-center flex-wrap">
                    <Rating
                      name="read-only"
                      value={productData.ratingsAverage || 0}
                      readOnly
                    />
                    <p className="embroidery-rating mb-0">
                      {productData.reviewsCount} ratings *{" "}
                      {productData.reviewsCount} Reviews{" "}
                      <span className="embroidery-totalbuyers">
                        ( {productData.buyersCount} Buyers)
                      </span>
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <p className="embroidery-Category py-2">
                  Category : {productData.category || "N/A"}
                </p>
                <div className=" justify-content-between flex-wrap align-items-center mb-5">
                  <h3 className="mb-0">
                    {productData.priceUpdateType === "" &&
                    new Date(
                      productData.priceValidityDate
                        ? productData.priceValidityDate
                        : ""
                    ) >= new Date() ? (
                      <span className="card-price">
                        $ {productData.price?.toFixed(2)}
                      </span>
                    ) : (
                      <div>
                        <h3 className="embroidery-price  mt-2">
                          {(() => {
                            const currentDate = new Date();
                            const validatyDate = productData.priceValidityDate
                              ? new Date(productData.priceValidityDate)
                              : null;
                            let newPrice = productData.price;
                            let originalPrice = productData.price;
                            let percentage = productData.pricePercentage;

                            // Check if price update is valid based on the validatyDate
                            if (
                              validatyDate &&
                              validatyDate >= currentDate &&
                              productData.priceUpdateType &&
                              productData.pricePercentage &&
                              productData.price !== undefined
                            ) {
                              if (
                                productData.priceUpdateType === "escalation"
                              ) {
                                newPrice =
                                  productData.price +
                                  (productData.price *
                                    productData.pricePercentage) /
                                    100;
                              } else if (
                                productData.priceUpdateType === "reduction"
                              ) {
                                newPrice =
                                  productData.price -
                                  (productData.price *
                                    productData.pricePercentage) /
                                    100;
                              }
                            }

                            // Return the newPrice, original price, and percentage for reduction
                            if (
                              productData.priceUpdateType === "reduction" &&
                              percentage !== undefined
                            ) {
                              return (
                                <>
                                  {/* <span style={{fontSize:"14px"}}>{`-${selectedProduct.pricePercentage}%`}</span> */}
                                  <span className="embroidery-price">
                                    $ {newPrice?.toFixed(2)}
                                  </span>{" "}
                                  <span
                                    className="og-price ms-1"
                                    style={{
                                      textDecoration: "line-through",
                                      color: "#848484",
                                      fontSize: "16px",
                                    }}
                                  >
                                    $ {originalPrice?.toFixed(2)}
                                  </span>
                                  <span className="odd">
                                    {" "}
                                    {percentage}% OFF
                                  </span>
                                </>
                              );
                            }

                            // Return only the newPrice if not a reduction
                            return newPrice !== undefined
                              ? `$ ${newPrice.toFixed(2)}`
                              : "Price not available";
                          })()}
                        </h3>
                      </div>
                    )}
                  </h3>

                  <div>
                    {isProductInCart(productData._id!) ? (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium me-2 mt-2"
                      >
                        Added
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleWishlist(productData, "cart")}
                        variant="contained"
                        className="button-medium me-2 mt-2"
                      >
                        Add To Cart
                      </Button>
                    )}
                    <Link to="/shopping-cart">
                      <Button
                        variant="contained"
                        onClick={() => handleWishlist(productData, "cart")}
                        className="button-medium  mt-2 me-2"
                      >
                        Buy Now
                      </Button>
                    </Link>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${API_URL}/public/subimages/${subImages[initial]}&quote=Check%20out%20this%20amazing%20product!`}
                      target="_blank"
                      className="me-1"
                    >
                      <img
                        src="/assets/facebook (5).png"
                        className="img-fluid soc-img"
                      />
                    </a>

                    <a
                      href={`https://in.pinterest.com/pin-builder?url=${
                        window.location.href
                      }&media=${API_URL}/public/subimages/${
                        subImages[initial]
                      }&description=Your%20Description%20Here"=${encodeURIComponent(
                        window.location.href
                      )}`}
                      target="_blank"
                      className="ms-1"
                    >
                      <img
                        src="/assets/social 1.png"
                        className="img-fluid soc-img"
                      />
                    </a>
                  </div>
                </div>
                <div>
                  {productData.category === "embroidery design" ? (
                    <>
                      <Table className="view-table">
                        <tbody>
                          <tr>
                            <td>Sizes (Inches)</td>
                            <td>
                              {productData.sizes ? productData.sizes : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Stitch Count</td>
                            <td>
                              {productData.stitchCount
                                ? productData.stitchCount
                                : "-"}
                            </td>
                          </tr>

                          <tr>
                            <td>Formats</td>
                            <td>
                              {productData.formats ? productData.formats : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>Quantity</td>
                            <td>
                              {productData.quantity
                                ? productData.quantity
                                : "-"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      {productData.keywords.length > 0 && (
                        <>
                          <h3 className="Keywords">Keywords</h3>
                          {productData.keywords.map((val: any) => (
                            <span className="keytags">{val}</span>
                          ))}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {productData.keywords.length > 0 && (
                        <>
                          <h3 className="Keywords">Keywords</h3>
                          {productData.keywords.map((val: any) => (
                            <span className="keytags">{val}</span>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="pt-3">
            <h2 className="product-desc">Product Description</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(productData.description || ""),
              }}
              style={{ fontSize: "16px", lineHeight: "1.5" }}
            />
          </div>
          {visibleReviews.length > 0 ? (
            <div className="reviews">
              <h3 className="product-desc">Reviews</h3>
              {visibleReviews.map((review) => (
                <div key={review._id}>
                  <h5>
                    {review.user.name}{" "}
                    <span className="review-date">
                      {new Date(review.date).toLocaleDateString()}
                    </span>
                  </h5>
                  <Rating name="read-only" value={review.rating} readOnly />
                  <p className="review-text">{review.review}</p>
                </div>
              ))}
              {reviewData.length > reviewsShown && (
                <Button onClick={handleSeeMore}>See More</Button>
              )}
            </div>
          ) : (
            ""
          )}
        </Container>
      </section>
    </>
  );
};

export default EmbroideryDesign;
